<template>
  <div style="height: inherit">
    <div style="height: inherit" v-if="$acl.canOneOf(['modelbuilder.models.manage', 'modelbuilder.components.manage'])">
      <div class="body-content-overlay" />
      <portal to="nav-bar-left-column">
        <page-title title="Model Builder" subtitle="Build your model and component to manage contents" />
      </portal>
      <model-edit-area
        :current-id="id"
        :type="type"
        :save-enabled="isDirty"
        v-if="(models.length > 0 || components.length > 0) && id != null"
        @content-deleted="onContentDeleted"
        @content-loaded="onContentLoaded"
        @input="onEditChange"
        @dirty-change="onDirtyChange"
      />
      <div class="d-flex justify-content-center align-items-center pt-1 flex-column h-75" v-if="(models.length > 0 || components.length > 0) && id == null">
        <b-img fluid :src="noSelectionImageUrl" />
        <h2 class="font-weight-normal mt-2">Select a model or component on the left panel to edit it</h2>
      </div>
      <div class="d-flex justify-content-center align-items-center pt-1 flex-column h-75" v-if="noContentTypes == true">
        <b-img fluid :src="noSelectionImageUrl" />
        <h2 class="font-weight-normal mb-2 mt-2">You don't have any model. Start creating one</h2>
        <div class="d-flex flex-column justify-content-center">
          <model-edit v-model="model" @new-content-type="onNewContentType" type="model" button-text="Create Model" class="mb-1 text-center" />
          <div class="text-center">or</div>
          <router-link :to="{ name: 'SystemModelBuilder' }" class="btn btn-primary mt-1 text-center">Go To System Models</router-link>
        </div>
      </div>
      <portal to="content-renderer-sidebar-left">
        <content-types-side-bar
          mode=""
          :models="models"
          :components="components"
          :current-id="id"
          :current-type="type"
          @select-item="onSelectItem"
          @new-content-type="onNewContentType"
          v-if="models.length > 0 || components.length > 0"
        />
      </portal>
    </div>

    <div v-else>
      <forbidden />
    </div>
    <b-modal id="confirmDirtyLeave" hide-footer title="Changes" size="md" centered hide-header v-model="dirtyConfirm">
      <div class="d-block text-left mt-2">
        <h4>Unsaved changes</h4>
        <p class="h6 font-weight-normal">
          There are unsaved changes. Exiting the page the changes will be lost.
        </p>
        <p class="h6 font-weight-normal">Are you sure you want to continue?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="dirtyConfirm = false">Cancel</b-button>
        <b-button class="" variant="warning" @click="onDirtyConfirmContinue">Continue</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { contentTypes } from '@/api/index'
import ContentTypesSideBar from './components/ContentTypesSideBar.vue'
import ModelEditArea from './components/ModelEditArea.vue'
import ModelEdit from './components/ModelEdit.vue'
import DefaultNotifications from '@/components/Notification/default'

export default {
  components: {
    ContentTypesSideBar,
    ModelEditArea,
    ModelEdit,
  },
  data() {
    return {
      isDirty: false,
      model: {},
      models: [],
      components: [],
      noContentTypes: null,
      id: null,
      type: 'model',
      noSelectionImage: require('@/assets/images/pages/not-authorized.svg'),
      dirtyConfirm: false,
      to: null,
    }
  },
  async created() {
    var self = this

    self.id = self.$route.query.id ? self.$route.query.id : null
    self.type = self.$route.query.type ? self.$route.query.type : 'model'

    await self.fetchData()
  },
  computed: {
    noSelectionImageUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.noSelectionImage = require('@/assets/images/pages/coming-soon-dark.svg')
      //   return this.noSelectionImage
      // }
      return this.noSelectionImage
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.checkDirtyDataOnExit)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.checkDirtyDataOnExit)
  },
  watch: {
    $route(to, from) {
      this.id = to.query.id
      this.type = to.query.type
    },
  },
  methods: {
    async fetchData() {
      await this.fetchContentTypes()
    },
    async fetchContentTypes() {
      let modelsRes = await contentTypes.getModels()
      this.models = modelsRes.data.data

      let componentsRes = await contentTypes.getComponents()
      this.components = componentsRes.data.data

      this.noContentTypes = this.models.length == 0 && this.components.length == 0
      this.noContentSelected = true
    },
    onSelectItem(e) {
      this.to = {
        name: 'ModelBuilder',
        query: { type: e.type, id: e.item.id },
      }

      if (this.isDirty) {
        this.dirtyConfirm = true
      } else {
        this.$router.push(this.to).catch(() => {})
      }
    },
    onEditChange(e) {
      const self = this
      if (e && e.type == 'model') {
        let index = self.$_.findIndex(self.models, m => {
          return m.id === e.id
        })

        if (index >= 0) {
          self.models[index] = e

          self.models = [...self.models]
        }
      }
    },
    onContentLoaded(e) {
      this.noContentSelected = false
    },
    onNewContentType(e) {
      if (e.type == 'model') {
        this.createModel(e.item)
      } else if (e.type == 'component') {
        this.createComponent(e.item)
      }
    },
    createModel(model) {
      var self = this
      contentTypes
        .createModel(model)
        .then(res => {
          self.$bus.$emit('model-created', { type: 'model', id: res.data.result })
          self.$bus.$emit('notification', { ...DefaultNotifications.saveSuccessful, text: 'Model successfully created' })

          self.fetchContentTypes()

          self.$router
            .push({
              name: 'ModelBuilder',
              query: { type: 'model', id: res.data.result },
            })
            .catch(() => {})
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.saveError })
        })
    },
    createComponent(component) {
      var self = this
      contentTypes
        .createComponent(component)
        .then(res => {
          self.$bus.$emit('component-created', { type: 'component', id: res.data.result })
          self.$bus.$emit('notification', { ...DefaultNotifications.saveSuccessful, text: 'Component successfully created' })

          self.fetchContentTypes()

          self.$router
            .push({
              name: 'ModelBuilder',
              query: { type: 'component', id: res.data.result },
            })
            .catch(() => {})
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.saveError })
        })
    },
    async onContentDeleted(e) {
      var self = this
      await this.fetchContentTypes()

      if (e.type == 'model') {
        self.$bus.$emit('model-deleted', e)
      } else {
        self.$bus.$emit('component-deleted', e)
      }

      this.$router
        .push({
          name: 'ModelBuilder',
          query: { type: e.type },
        })
        .catch(() => {})
    },
    prepareToExit() {
      if (this.isDirty) {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        if (answer) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    checkDirtyDataOnExit(e) {
      if (!this.isDirty) return
      e.preventDefault()
      e.returnValue = ''
    },
    onDirtyChange(event) {
      this.isDirty = event
    },
    onDirtyConfirmContinue() {
      this.dirtyConfirm = false
      this.isDirty = false
      this.$router.push(this.to)
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.to = to
      this.dirtyConfirm = true
      next(false)
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '@/assets/scss/model-builder/model-builder.scss';
@import '@/assets/scss/model-builder/model-builder-list.scss';
</style>
