import { extend, localize } from 'vee-validate'

extend('model-api-key', val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }

  const re = /^([a-zA-Z]{1,1}([a-zA-Z0-9\_\-]+)?){1,}$/

  /* eslint-enable no-useless-escape */
  if (re.test(val)) {
    return true
  }

  return 'The key must start with a-Z and accepts only alphanumeric chars and - _'
})

extend('model-api-key-reserved-words', val => {
  if (val == 'media') return `${val} is a reserved word and cannot be used as a model key`

  return true
})

extend('field-api-key', val => {
  return true
})

extend('field-api-key-reserved-words', val => {
  return true
})
export default this
