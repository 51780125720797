<template>
  <div class="model-builder-navbar">
    <header class="model-builder-header">
      <!-- Avatar & Name -->
      <div class="d-flex align-items-center" v-if="model != null">
        <!-- Toggle Icon -->
        <div class="sidebar-toggle d-block d-lg-none mr-1">
          <feather-icon icon="MenuIcon" class="cursor-pointer" size="21" @click="mqShallShowLeftSidebar = true" />
        </div>
        <b-media class="align-self-center">
          <template #aside>
            <b-avatar size="42" alt="" class="field-avatar">
              <img :src="imageUrl" width="40%" v-if="!icon" />
              <feather-icon :icon="icon" v-if="icon" class="text-dark" size="18" />
            </b-avatar>
          </template>
          <h5 class="media-heading mb-0">
            {{ model.name }}
          </h5>
          <b-card-text>
            <b-badge pill variant="light-dark font-weight-normal font-small-1">
              {{ model.key }}
            </b-badge>
            <b-badge pill variant="light-dark font-weight-normal font-small-1" style="margin-left:0.3rem;">
              {{ model.id }}
            </b-badge>
          </b-card-text>
        </b-media>
      </div>

      <!-- Contact Actions -->

      <portal-target name="model-edit-header-buttons" slim />
    </header>
  </div>
</template>
<script>
export default {
  props: ['model'],
  components: {},
  data() {
    return {}
  },
  computed: {
    imageUrl() {
      return require('@/assets/images/fields/models.svg')
    },
    icon() {
      return this.model.properties && this.model.properties.icon ? `${this.model.properties.icon}Icon` : null
    },
  },
  methods: {},
}
</script>
