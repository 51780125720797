<template>
  <section id="dashboard-ecommerce" class=" container">
    <portal to="nav-bar-left-column"><page-title title="Dashboard"/></portal>

    <b-row class="match-height">
      <b-col xl="12" lg="12" md="12">
        <links />
      </b-col>
      <b-col xl="8" lg="8" md="8">
        <counter-statistics :data="counters" />
      </b-col>
      <b-col xl="4" lg="4" md="4">
        <counter-assets :data="assetsSize" />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12" md="12">
        <content-statistics :data="contentStats" />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12" lg="12" v-if="$acl.can('usersroles.users.read')">
        <last-users />
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { reports } from '@/api/index'
import { mapGetters } from 'vuex'
import AtlasLogo from '@core/layouts/components/Logo.vue'
import CounterStatistics from './components/CounterStatistics.vue'
import CounterAssets from './components/CounterAssets.vue'
import ContentStatistics from './components/ContentStatistics.vue'
import LastUsers from './components/LastUsers.vue'
import Links from './components/Links.vue'

export default {
  name: 'Dashboard',
  components: {
    AtlasLogo,
    CounterStatistics,
    CounterAssets,
    ContentStatistics,
    LastUsers,
    Links,
  },
  data() {
    return {
      loading: false,
      counters: null,
      assetsSize: null,
      contentStats: null,
    }
  },
  created() {
    this.fetchCounters()
    this.fetchContentStats()
  },
  mounted() {},
  methods: {
    async fetchCounters() {
      const response = await reports.getCounters()
      this.counters = response.data
      this.assetsSize = response.data.assetsSize
    },
    async fetchContentStats() {
      const response = await reports.getContentStats()
      this.contentStats = response.data
    },
  },
  computed: {
    ...mapGetters({ me: 'Me' }),
  },
}
</script>
