<template>
  <b-card title="Contents">
    <v-chart class="chart" :option="options" autoresize auto-resize :theme="theme" style="min-height: 400px" v-if="data && options" />
    <div class="d-flex justify-content-center align-items-center pt-1 flex-column h-25" v-if="data && !options">
      <b-img :src="noDataImage" style="max-height:200px;" />
      <h2 class="font-weight-normal mb-2 mt-2">No data to display</h2>
    </div>
  </b-card>
</template>
<script>
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import theme from './theme.json'
import { GridComponent, TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
use([CanvasRenderer, BarChart, GridComponent, TitleComponent, TooltipComponent, LegendComponent])

export default {
  name: 'ContentStatistics',
  props: ['data'],
  components: {
    VChart,
  },
  data() {
    return {
      theme,
      noDataImage: require('@/assets/images/pages/no-data.svg'),
    }
  },
  computed: {
    options() {
      if (this.data && this.data.totals.length) {
        return {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            left: 0,
          },
          grid: {
            left: '50px',
            right: '30px',
            bottom: '60px',
          },
          xAxis: [
            {
              type: 'category',
              data: this.data.totals.map(t => t.name),
              axisLabel: {
                show: true,
                hideOverlap: false,
                interval: 0,
                rotate: 45,
                width: '70',
                overflow: 'truncate',
                ellipsis: 'truncate',
              },
              // nameTextStyle: {
              //   align: 'top',
              // },
            },
          ],
          yAxis: [
            {
              type: 'value',
              splitLine: { show: false },
            },
          ],
          series: this.mapSeries(),
        }
      } else {
        return null
      }
    },
  },
  methods: {
    mapSeries() {
      let published = {
        name: 'Published',
        type: 'bar',
        stack: 'advertising',
        data: [],
        color: '#826AF9', //'#28c76f',
        barMinWidth: '10px',

        label: {
          show: false,
        },
      }

      let unpublished = {
        name: 'Unpublished',
        type: 'bar',
        stack: 'advertising',
        data: [],
        color: '#ff9f43',
        barMinWidth: '10px',

        label: {
          show: false,
        },
      }

      for (let i = 0, len = this.data.totals.length; i < len; i++) {
        published.data.push(this.data.totals[i].published)
        unpublished.data.push(this.data.totals[i].unpublished)
      }

      return [published, unpublished]
    },
  },
}
</script>
